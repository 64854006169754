import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import FounderFitBanner from '../components/koble/FounderFitBanner'
import FromTeamBehind from '../components/koble/FromTeamBehind'
import InvestmentInfo from '../components/koble/InvestmentInfo'
import Quote from '../components/koble/Quote'
import KobleRightChoice from '../components/koble/KobleRightChoice'
import FAQ from '../components/koble/FAQ'
import FounderFitPricingBanner from '../components/koble/FounderFitPricingBanner'

// eslint-disable-next-line
export const FounderFitPageTemplate = ({
  founderFitBanner,
  fromTeamBehind,
  investmentInfo,
  quote,
  founderFitPricingBanner,
  faq,
  kobleRightChoice
}) => {
  return (
    <div>
      <FounderFitBanner founderFitBanner={founderFitBanner} />
      <FromTeamBehind fromTeamBehind={fromTeamBehind} />
      <InvestmentInfo investmentInfo={investmentInfo} />
      <Quote {...quote} />
      <FounderFitPricingBanner
        founderFitPricingBanner={founderFitPricingBanner}
      />
      <FAQ faq={faq} />
      <div className="divider"></div>
      <KobleRightChoice {...kobleRightChoice} />
    </div>
  )
}

FounderFitPageTemplate.propTypes = {
  founderFitBanner: PropTypes.object,
  fromTeamBehind: PropTypes.object,
  investmentInfo: PropTypes.array,
  quote: PropTypes.object,
  founderFitPricingBanner: PropTypes.object,
  faq: PropTypes.object,
  kobleRightChoice: PropTypes.object,
  pageName: PropTypes.string
}

const FounderFitPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <FounderFitPageTemplate
        founderFitBanner={post.frontmatter.founderFitBanner}
        fromTeamBehind={post.frontmatter.fromTeamBehind}
        investmentInfo={post.frontmatter.investmentInfo}
        quote={post.frontmatter.quote}
        founderFitPricingBanner={post.frontmatter.founderFitPricingBanner}
        faq={post.frontmatter.faq}
        kobleRightChoice={post.frontmatter.kobleRightChoice}
        pageName={'founderFitPage'}
      />
    </Layout>
  )
}

FounderFitPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default FounderFitPage

export const FounderFitPageQuery = graphql`
  query FounderFitPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        founderFitBanner {
          title {
            cssClass
            text
          }
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH, height: 770)
            }
          }
        }
        fromTeamBehind {
          text
          images {
            logo {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
        investmentInfo {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH, height: 770)
            }
          }
        }
        quote {
          description
          note
          designation
        }
        faq {
          title {
            cssClass
            text
          }
          questionAnswers {
            question
            mobileViewQuestion
            answers {
              answer
            }
          }
        }
        kobleRightChoice {
          title {
            text
            cssClass
          }
          description
          ctaDetails {
            text
            bgColor
            link
            type
          }
          video {
            extension
            publicURL

            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        founderFitPricingBanner {
          title {
            cssClass
            text
          }
          plan {
            title
            priceText
            offerType
            offerCategory
            offerDescription
            rightHoverIcon {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
            ctaDetails {
              text
              bgColor
              link
              type
            }
            notes {
              note
              noteIcon {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
