import React from 'react'
import PropTypes from 'prop-types'
import '../scss/from-team-behind.scss'
import RenderGatsByImage from '../common/RenderGatsByImage'

export default function FromTeamBehind(props) {
  const { fromTeamBehind } = props

  return (
    <React.Fragment>
      <div className="www-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="logos d-flex justify-content-between align-items-center">
                <div className="text">{fromTeamBehind.text}</div>

                {fromTeamBehind.images.map((item, index) => {
                  return (
                    <div className="logo" key={index}>
                      <RenderGatsByImage imageSource={item.logo} width={100} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

FromTeamBehind.propTypes = {
  fromTeamBehind: PropTypes.object
}
