import React from 'react'
import PropTypes from 'prop-types'
import '../scss/faq.scss'
import { Accordion } from 'react-bootstrap'

export default function FAQ(props) {
  const { faq } = props

  return (
    <React.Fragment>
      <div className="faq-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-md-center styled-text">
                <div className="oval">FAQ</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="">
                <Accordion>
                  {faq.questionAnswers.map((item, index) => {
                    return (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          <span className="for-desktop">{item.question}</span>
                          <span className="for-mobile">
                            {item.mobileViewQuestion}
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          {item.answers.map((item, index) => {
                            return (
                              <div
                                className="mb-4"
                                key={index}
                                dangerouslySetInnerHTML={{
                                  __html: item.answer
                                }}
                              />
                            )
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

FAQ.propTypes = {
  WhyCoble: PropTypes.object
}
