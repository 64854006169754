import React from 'react'
import PropTypes from 'prop-types'
import TextAndImage from './TextAndImage'

export default function InvestmentInfo(props) {
  const { investmentInfo } = props

  return (
    <React.Fragment>
      {investmentInfo.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <TextAndImage {...item} />
            <div className="sub-divider" />
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

InvestmentInfo.propTypes = {
  InvestmentInfo: PropTypes.object
}
