import React from 'react'
import PropTypes from 'prop-types'
import '../scss/founderfit-pricing-banner.scss'
import RenderGatsByImage from '../common/RenderGatsByImage'
import CTAButton from '../common/CTAButton'

export default function FounderFitPricingBanner(props) {
  const { founderFitPricingBanner } = props

  return (
    <React.Fragment>
      <div className="ff-pb-container">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="d-flex align-item-center styled-text">
                {founderFitPricingBanner.title.map((item, index) => {
                  return (
                    <div className={item.cssClass} key={index}>
                      {item.text}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="row card-section">
            {/*{founderFitPricingBanner.plan.map((item, index) => {})}*/}
            {
              <div className="col-12 pb-cards">
                <div>
                  <div className="heading align-items-center justify-content-center">
                    <div className="name">
                      {founderFitPricingBanner.plan.title}
                    </div>
                    <div className="d-flex justify-content-center align-items-center pb-price-with-type">
                      <div className="currency-text">$</div>
                      <div className="price-text">
                        {founderFitPricingBanner.plan.priceText}
                      </div>
                      <div className="offer-type">
                        {founderFitPricingBanner.plan.offerType}
                      </div>
                      <div className="offer-category">
                        {founderFitPricingBanner.plan.offerCategory}
                      </div>
                    </div>
                    <div className="offer-description">
                      {founderFitPricingBanner.plan.offerDescription}
                    </div>
                    {founderFitPricingBanner.plan.type && (
                      <div className="type">
                        {founderFitPricingBanner.plan.type}
                      </div>
                    )}
                  </div>
                  <div className="body">
                    {founderFitPricingBanner.plan.question && (
                      <div className="question">
                        {founderFitPricingBanner.plan.question}
                      </div>
                    )}
                    {founderFitPricingBanner.plan.notes.map((note, index) => {
                      return (
                        <div className="note mb-3 d-flex" key={index}>
                          <div className=" note-icon">
                            <RenderGatsByImage
                              imageSource={note.noteIcon}
                              alt={note.text}
                              width={14}
                              height={14}
                            />
                          </div>
                          <div className="note-desc">{note.note}</div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="footer align-items-center d-flex justify-content-center">
                    <CTAButton
                      text={founderFitPricingBanner.plan.ctaDetails.text}
                      link={founderFitPricingBanner.plan.ctaDetails.link}
                      type={founderFitPricingBanner.plan.ctaDetails.type}
                      rightHoverIcon="/img/koble/arrow_purple_icon.svg"
                      bgColor={founderFitPricingBanner.plan.ctaDetails.bgColor}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </React.Fragment>
  )
}

FounderFitPricingBanner.propTypes = {
  founderFitPricingBanner: PropTypes.object
}
