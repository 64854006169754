import React from 'react'
import PropTypes from 'prop-types'
import '../scss/founder-fit-banner.scss'
import RenderGatsByImage from '../common/RenderGatsByImage'
import Word from './Word'

export default function FounderFitBanner(props) {
  const { founderFitBanner } = props

  return (
    <React.Fragment>
      <div className="d-flex founder-fit-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="ff-banner-container">
                <div className="banner-heading-container">
                  {/*<div className="ff-heading"> {founderFitBanner.heading}</div>*/}

                  <div className="d-flex ff-heading styled-text justify-content-center">
                    {' '}
                    {founderFitBanner.title.map((item) => (
                      <Word key={item.text} {...item} />
                    ))}
                  </div>
                </div>
                <div className="ff-description">
                  {founderFitBanner.description}
                </div>
                <div className="ff-image-container">
                  {/*<img alt="unicorn" className="ff-unicorn-image" src="/img/koble/founderfit_unicorn.png"/>
                                    <img alt="blur" className="ff-blue-image" src="/img/koble/blur_triangle.png"/>*/}

                  <div className="ff-unicorn-image">
                    <RenderGatsByImage imageSource={founderFitBanner.image} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

FounderFitBanner.propTypes = {
  height: PropTypes.number,
  founderFitBanner: PropTypes.object
}
