import React from 'react'
import RenderGatsByImage from '../common/RenderGatsByImage'
import '../scss/text-and-image.scss'

export default function TextAndImage({ title, description, image }) {
  return (
    <div className="max-1024-container">
      <div className="ii-container">
        <div className="container">
          <div className="row column-reverse-in-mobile">
            <div className="col-12 col-md-6 pe-md-0">
              <div className="d-flex align-items-center h-100">
                <div className="">
                  <div className="olg-text">
                    <div>{title}</div>
                  </div>
                  <div className="olg-description mb-md-0">
                    <div className="mt-4">{description}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="d-flex justify-content-end ">
                <div className="chart-img d-flex justify-content-center flex-grow-1">
                  <RenderGatsByImage
                    imageSource={image}
                    width={510}
                    height={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
