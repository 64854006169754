import React from 'react'
import PropTypes from 'prop-types'
import '../scss/quote.scss'

export default function Quote({ description, note, designation }) {
  return (
    <React.Fragment>
      <div className="vcm-container">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="d-flex justify-content-center">
                <div className="mt-2 quote-icon">
                  <img src="img/koble/quote.png" alt="quote" />
                </div>
                <div className="clr-white">
                  <div className="msg">{description}</div>
                  <div className="mt-4 d-flex vcm-footer-text">
                    <span>{note}</span>
                    {designation && <div className="vc-tiled"></div>}
                    {designation && (
                      <span className="vc-col-designation">{designation}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Quote.propTypes = {
  description: PropTypes.string,
  designation: PropTypes.string,
  note: PropTypes.string
}
